<template>
  <div class="size-skeleton__container">
    <div
      v-for="item in attrList"
      :key="`size-skeleton__${item.attr_id}`"
      class="product-intro__size"
    >
      <div
        class="product-intro__size-title no-flex"
        tabindex="0"
        :aria-label="item.attr_name"
      >
        {{ item.attr_name }}
      </div>
      <div
        v-for="subItem in item.attr_value_list"
        :key="`size-skeleton__item-${subItem.attr_id}`"
        class="product-intro__size-radio size-skeleton__item"
      >
        <div class="product-intro__size-radio-inner">
          {{ getText(subItem) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SizeSkeleton',
  props: {
    attrList: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    getText(sizeItem) {
      let str = ''
      if (sizeItem.attr_std_value) {
        str += `${sizeItem.attr_std_value} / `
      }
      str += sizeItem.attr_value_name
      return str
    }
  }
}
</script>

<style lang="less">
.size-skeleton {
  &__container {
    .no-flex {
        display: block;
    }
  }
  &__item {
    margin: 0 16px 16px 0;
    background-color: #e5e5e5;
    color: #e5e5e5;
  }
}
</style>
