import { createApp } from 'vue'
import { registerBaseContext } from 'public/src/pages/common/common_inject.js'

let ProductFindSrimilarFloatInstance = undefined

export default {
  async showModal(data, extendParams) {
    // 如果组件已渲染，则返回即可
    if (ProductFindSrimilarFloatInstance) {
      return ProductFindSrimilarFloatInstance.showModal(data, extendParams)
    }
    if (typeof window === 'undefined') return

    try {
      const ProductFindSrimilarFloat = await import('./ProductFindSrimilarFloat.vue')
      const ProductFindSrimilarFloatAPP = createApp(ProductFindSrimilarFloat.default)
      registerBaseContext(ProductFindSrimilarFloatAPP)
      const instance = ProductFindSrimilarFloatAPP.mount(document.createElement('div'))
      // 要挂载的元素
      const parent = document.body
      parent.appendChild(instance.$el)
      // 将组件实例赋值
      // eslint-disable-next-line require-atomic-updates
      ProductFindSrimilarFloatInstance = instance
      // 展示
      ProductFindSrimilarFloatInstance.showModal(data, extendParams)
    } catch (error) {
      console.log('import ProductFindSrimilarFloat vue error', error)
    }
  },
  hideModal() {
    if (typeof window === 'undefined') return
    ProductFindSrimilarFloatInstance?.hideModal?.()
  }
}
