<template>
  <s-dialog
    v-model:visible="visible"
    :show-close="true"
    class="size-edit"
    :immediately-render="true"
    :append-to-body="true"
    type="W996"
  >
    <template #title>
      {{ language.SHEIN_KEY_PC_19346 }}
    </template>

    <div
      v-show="typeof recommendResult === 'object'"
      class="size-edit__result"
    >
      <div class="context">
        {{ language.SHEIN_KEY_PC_19349 }}
      </div>
      <div class="size">
        {{ recommendResult.size }}
      </div>
    </div>

    <div
      v-show="recommendResult == 1"
      class="size-edit__result none"
    >
      <div class="context">
        {{ language.SHEIN_KEY_PC_19353 }}
      </div>
    </div>

    <div class="size-edit__size-switch">
      <div class="my-size">
        {{ language.SHEIN_KEY_PC_17380 }}
      </div>
      <div class="switch-btn">
        <div
          class="cm"
          :class="{ active: unitType == 'cm' }"
          @click="unitTypeClick('cm')"
        >
          CM
        </div>
        <div
          class="in"
          :class="{ active: unitType == 'inch' }"
          @click="unitTypeClick('inch')"
        >
          IN
        </div>
      </div>
    </div>

    <div
      v-show="recommendResult == 0"
      class="size-edit__form"
    >
      <label
        for="foot_length"
        class="size-edit__form-item"
      >
        <div
          class="placeholder"
          :class="{ empty: inputActive.foot_length }"
        >
          {{ language.SHEIN_KEY_PC_19351 }} ({{ unitType }})
        </div>
        <input
          id="foot_length"
          v-model="form.foot_length"
          type="text"
          placeholder
          @input="inputHandle('foot_length', $event)"
          @focus="focusHandle('foot_length')"
          @blur="blurHandle('foot_length')"
        />
      </label>
      <label
        for="ball_girth"
        class="size-edit__form-item"
      >
        <div
          class="placeholder"
          :class="{ empty: inputActive.ball_girth }"
        >
          {{ language.SHEIN_KEY_PC_19350 }} ({{ unitType }})
        </div>
        <input
          id="ball_girth"
          v-model="form.ball_girth"
          type="text"
          placeholder
          @input="inputHandle('ball_girth', $event)"
          @focus="focusHandle('ball_girth')"
          @blur="blurHandle('ball_girth')"
        />
      </label>
      <label
        for="size"
        class="size-edit__form-item size"
      >
        <div class="placeholder">{{ language.SHEIN_KEY_PC_19352 }}</div>
        <div class="local">
          <div class="local__select">
            <div
              class="local__selected"
              :class="{ active: form.size_type }"
              @click="multiSelectOption = true"
            >
              {{ form.size_type || language.SHEIN_KEY_PC_15803 }}
              <Icon
                v-if="multiSelectOption"
                name="sui_icon_more_up_18px_1"
                size="18px"
              />
              <Icon
                v-else
                name="sui_icon_more_down_18px_1"
                size="18px"
              />
              <div class="line"></div>
            </div>
            <div
              class="size-edit__units"
              style="font-size: 12px"
            >
              <ul
                v-show="multiSelectOption"
                @mouseleave="multiSelectOption = false"
              >
                <li
                  v-for="(val, key) in localsize"
                  :key="key"
                  :class="{
                    'size-edit__country-select': key === form.size_type,
                  }"
                  @mouseenter="form.size_type = key"
                  @click="multiSelectOption = false"
                >
                  {{ key }}
                </li>
              </ul>
            </div>
            <div class="line"></div>
          </div>
          <input
            v-model="form.size"
            type="text"
            :readonly="!form.size_type"
            :placeholder="language.SHEIN_KEY_PC_19352"
            @input="inputHandle('size', $event)"
          />
        </div>
      </label>
    </div>
    <div
      v-show="recommendResult != 0"
      class="size-edit__form"
    >
      <div class="left">
        <div class="label">
          {{ language.SHEIN_KEY_PC_19351 }}:
        </div>
        <div class="label">
          {{ language.SHEIN_KEY_PC_19350 }}:
        </div>
        <div class="label">
          {{ language.SHEIN_KEY_PC_19352 }}:
        </div>
      </div>
      <div class="right">
        <div class="label">
          <template v-if="form.foot_length !== ''">
            {{ form.foot_length }}{{ unitType }}
          </template>
          <template v-else>
            —
          </template>
        </div>
        <div class="label">
          <template v-if="form.ball_girth !== ''">
            {{ form.ball_girth }}{{ unitType }}
          </template>
          <template v-else>
            —
          </template>
        </div>
        <div class="label">
          <template v-if="form.size_type != '' && form.size != ''">
            {{ form.size_type }}{{ form.size }}
          </template>
          <template v-else>
            —
          </template>
        </div>
      </div>
    </div>
    <div
      v-show="showError"
      class="size-edit__err"
    >
      {{ language.SHEIN_KEY_PC_19354 }}
    </div>
    <div
      class="size-edit__submit"
      @click="querySizeRecommend"
    >
      {{
        recommendResult == 0
          ? language.SHEIN_KEY_PC_17178
          : language.SHEIN_KEY_PC_19346
      }}
    </div>
    <div
      v-if="sizeTemplate && recommendResult == 0"
      class="size-edit__measure"
    >
      <div class="title">
        {{ language.SHEIN_KEY_PC_18043 }}
      </div>
      <div class="measure">
        <div
          v-if="sizeTemplate.context.length"
          class="measure__list"
        >
          <div
            v-for="(item, key) in sizeTemplate.context"
            :key="key"
            class="measure__item"
          >
            <div class="name">
              {{ item.sort }}.{{ item.name }}
            </div>
            <div class="content">
              {{ item.description }}
            </div>
          </div>
        </div>
        <div
          v-if="sizeTemplate.image"
          class="measure__image"
        >
          <img
            :src="sizeTemplate.image"
            alt
          />
        </div>
      </div>
    </div>
    <div
      v-if="recommendResult != 0"
      class="size-edit__size-guid"
      @click="showSizeGuid"
    >
      {{ language.SHEIN_KEY_PC_16557 }}
      <Icon
        name="sui_icon_more_right_12px_1"
        size="12px"
        class="selected-icon"
      />
    </div>
  </s-dialog>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { transformImg } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import { Icon } from '@shein-aidc/icon-vue3'
daEventCenter.addSubscriber({ modulecode: '1-8-6' })

export default {
  components: { Icon },
  props: {
    language: {
      default() {
        return {}
      },
      type: Object,
    },
    value: {
      type: Boolean,
      default: false,
    },
    productIntroData: {
      default() {
        return {}
      },
      type: Object,
    },
    unit: {
      default: 0, // 0 cm || 1 imch
      type: Number,
    },
  },
  data() {
    return {
      unitType: 'cm',
      multiSelectOption: false,
      showError: false,
      recommendResult: 0, // 0 初始状态，没有推荐结果也没有查询推荐，1已经查询推荐，但是没有推荐数据，对象，推荐结果
      inputActive: {
        foot_length: false,
        ball_girth: false,
      },
      form: {
        size_type: '',
        size: '',
        foot_length: '',
        ball_girth: '',
      },
      localsize: {
        EUR: 'EUR',
        US: 'US',
        UK: 'UK',
        BR: 'BR',
        CHN: 'CHN',
      },
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('update:value', val)
      },
    },
    sizeTemplate() {
      const context =
        this.productIntroData.detail?.sizeTemplate?.description_multi || []
      const image = transformImg({
        img: this.productIntroData.detail?.sizeTemplate?.image_url,
      })
      if (!context && !image) return null
      return { context, image }
    },
    multiLocalSize() {
      return this.productIntroData.multiLocalSize?.size_rule_list || {}
    },
    currentUnit() {
      return this.unit === 0 ? 'cm' : 'inch'
    },
  },
  watch: {
    visible(val) {
      if (val) {
        //  this.initVisible()
        sessionStorage.setItem('enable_size_recommend', '1')
      } else {
        if (this.recommendResult?.size) {
          this.$emit('freshRecommendSize', {
            size: this.recommendResult?.size,
          })
        }
      }
    },
    'form.size_type'(val) {
      if (val == '') {
        this.form.size = ''
      }
    },
  },
  mounted() {
    this.initVisible()
    if (this.checkForm() && this.productIntroData.rule_id)
      this.querySizeRecommend(true)
  },
  methods: {
    showSizeGuid() {
      this.$emit('sizeGuideModalShow', true)
    },
    inputHandle(key, e) {
      const {
        data,
        target: { value },
      } = e
      if (data === '.' && value.match(/\./g).length > 1) {
        const hasPoint = this.form[key].indexOf('.')
        this.form[key] = this.form[key].replace(/[^\d]/g, '')
        const sp = this.form[key].split('')
        sp.splice(hasPoint, 0, '.')
        this.form[key] = sp.join('')
      } else if (this.form[key] == '.') {
        this.form[key] = ''
      } else {
        this.form[key] = value.toString().replace(/[^\d|\.]/g, '')
      }
      if (/(\.\d{1,})$/.test(this.form[key])) {
        this.form[key] = (+this.form[key]).toFixed(1)
      }
      e.target.value = this.form[key]
      localStorage.setItem(
        'check_my_size',
        JSON.stringify({ f: this.form, u: this.unitType })
      )
    },
    initVisible() {
      let sizeRecommend = localStorage.getItem('check_my_size') || null
      try {
        sizeRecommend = /^\{/.test(sizeRecommend)
          ? JSON.parse(sizeRecommend)
          : null
      } catch (e) {
        sizeRecommend = null
      }
      if (sizeRecommend) {
        this.form = {
          ...this.form,
          ...(sizeRecommend.f || {}),
        }
        this.unitTypeClick(sizeRecommend.u || this.currentUnit, false)
        // this.recommendResult = sizeRecommend?.s || 0
        this.focusHandle('foot_length')
        this.focusHandle('ball_girth')
      } else {
        this.form = {
          size_type: '',
          size: '',
          foot_length: '',
          ball_girth: '',
        }
        this.unitTypeClick(this.currentUnit, false)
      }
    },
    focusHandle(key) {
      this.inputActive[key] = true
    },
    blurHandle(key) {
      this.form[key] = this.form[key].replace(/\.$/, '')
      this.inputActive[key] = !!this.form[key]
    },
    unitTypeClick(size, isComputed = true) {
      this.unitType = size
      if (!isComputed) return
      const base = 0.3937007874
      if (/^\d/.test(this.form.foot_length)) {
        this.form.foot_length =
          +(
            this.unitType === 'inch'
              ? +this.form.foot_length * base
              : +this.form.foot_length / base
          ).toFixed(1) + ''
      }
      if (/^\d/.test(this.form.ball_girth)) {
        this.form.ball_girth =
          +(
            this.unitType === 'inch'
              ? +this.form.ball_girth * base
              : +this.form.ball_girth / base
          ).toFixed(1) + ''
      }
    },
    checkForm() {
      const { foot_length, ball_girth, size } = this.form
      // 脚长脚肥用户只填写一个时，或穿尺码没有填
      if ((!foot_length || !ball_girth) && !size) return false
      return true
    },
    async querySizeRecommend(isInit = false) {
      if (this.recommendResult !== 0) {
        this.recommendResult = 0
        this.showError = false
        return
      }
      this.showError = !this.checkForm()
      if (this.showError) {
        return
      }
      if(this.productIntroData?.rule_type != '0'){
        return
      }
      schttp({
        url: '/api/productInfo/sizeRecommend/get',
        params: {
          skc: this.productIntroData.multiLocalSize?.skc,
          ...this.form,
          rule_id: this.productIntroData.rule_id,
          units: this.unitType,
        },
      }).then((result) => {
        if (result.recommend_size) {
          const { recommend_size, size_guide } = result || {}
          this.recommendResult = {
            size: recommend_size,
            guid: size_guide,
            u: this.unitType,
          }
          this.$emit('freshRecommendSize', {
            size: this.recommendResult?.size,
            isInit: isInit === true,
          })
        } else {
          // localStorage.removeItem('check_my_size')
          this.recommendResult = isInit === true ? 0 : 1
          // if (isInit === true) this.form = { size_type: '', size: '', foot_length: '', ball_girth: ''}
        }
        daEventCenter.triggerNotice({
          daId: '1-8-6-79',
          extraData: {
            goods_id: this.productIntroData.detail.goods_id,
            size_result: result.recommend_size ? 0 : 1,
          },
        })
      })
    },
  },
  emits: ['update:value', 'freshRecommendSize', 'sizeGuideModalShow'],
}
</script>

<style lang="less">
.size-edit{/*// font-family: ArialMT;*/
  .sui-dialog {
    &__body {
      width: auto !important; /* stylelint-disable-line declaration-no-important */
    }
  }
  &__result {
    background: #f6f6f6;
    padding: 31px 0;
    text-align: center;
    margin-bottom: 33px;
    .context,
    .none {
      font-size: 16px;
      color: #333333;
      line-height: 18px;
    }
    .size {
      margin-top: 8px;
      font-size: 20px;
      font-family: Arial-Black, Arial;
      font-weight: 900;
      color: #222222;
      line-height: 27px;
    }
  }
  &__size-switch {
    height: 26px;
    width: 100%;
    .my-size {
      display: inline-block;
      float: left;
      font-size: 18px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #000000;
      line-height: 21px;
    }
    .switch-btn {
      display: inline-block;
      float: right;
      font-size: 12px;
      font-family: SFUIDisplay-Regular, SFUIDisplay;
      font-weight: 400;
      line-height: 14px;
      width: 74px;
      color: #222;
      div {
        display: inline-block;
        float: right;
        width: 35px;
        text-align: center;
        height: 23px;
        line-height: 23px;
        border: 1px solid #222222;
        cursor: pointer;
        user-select: none;
      }
      .active {
        background: #222222;
        color: #fff;
      }
    }
  }
  &__form {
    width: 100%;
    display: inline-block;
    .left,
    .right {
      font-size: 16px;
      color: #222222;
      line-height: 18px;
      display: inline-block;
      .label {
        margin-top: 15px;
      }
    }
    .right {
      margin-left: 14px;
    }
  }
  &__form-item {
    // height: 29px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: calc(50% - 20px);
    display: inline-block;
    cursor: text;
    padding: 0 10px;
    height: 47px;
    float: left;
    position: relative;
    margin-top: 18px;
    .placeholder {
      position: absolute;
      z-index: -1; /* stylelint-disable-line declaration-property-value-blacklist */
      font-size: 12px;
      color: #0000003c;
      line-height: 14px;
      top: 7px;
      transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0s;
      transform: translate3d(0, 9px, 0);
      &.empty {
        transform: translate3d(0, 0, 0);
      }
    }
    input {
      width: 100%;
      height: 14px;
      font-weight: bold;
      color: #222222;
      line-height: 0.45rem;
      border: none;
      outline: none;
      margin-top: 23px;
      margin-left: -2px;
      background: transparent;
      &::placeholder {
        font-size: 12px;
        font-weight: 100;
        color: #00000028;
      }
    }
    &:nth-child(2) {
      margin-left: 20px;
    }
    &.size {
      width: calc(100% - 20px);
      display: flex;
      flex-direction: column;
      .placeholder {
        position: relative;
        top: unset;
      }
      .local {
        display: flex;
        margin-top: 10px;
        &__selec {
          display: flex;
        }
        input {
          margin-top: unset;
        }
      }
    }
  }
  &__err {
    font-size: 12px;
    color: #ff411c;
    line-height: 14px;
    padding-top: 8px;
  }
  &__submit {
    font-size: 16px;
    font-weight: normal;
    color: #ffffff;
    line-height: 18px;
    width: 234px;
    height: 43px;
    line-height: 43px;
    background: #222222;
    margin: 16px auto;
    text-align: center;
    cursor: pointer;
    user-select: none;
  }
  &__measure {
    margin-top: 39px;
    .title {
      font-size: 18px;
      font-family: Arial-BoldMT, Arial;
      font-weight: normal;
      color: #000000;
      line-height: 21px;
    }
    .measure {
      margin-top: 12px;
      display: block;
      &__list {
        display: inline-block;
        float: left;
        width: calc(100% - 219px);
        .name {
          font-size: 13px;
          color: #222222;
          line-height: 15px;
        }
        .content {
          font-size: 13px;
          margin-top: 8px;
          color: #222222;
          line-height: 15px;
        }
      }
      &__image {
        float: right;
        width: 176px;
        img {
          width: 100%;
        }
      }
      &__item:not(:first-child) {
        margin-top: 16px;
      }
    }
  }
  &__size-guid {
    cursor: pointer;
    user-select: none;
    margin: 0 auto;
    font-size: 12px;
    color: #1860a7;
    line-height: 16px;
    width: max-content;
  }
  &__units {
    position: relative;
    cursor: pointer;
    z-index: @zindex-hover;
    font-size: 14px;
    vertical-align: bottom;
    color: #222;
    ul {
      position: absolute;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
      border: 1px solid rgba(242, 242, 242, 1);
      padding: 5px 0;
      left: -11px;
      right: unset;
      top: 3px;
    }
    li {
      width: 138px;
      height: 36px;
      line-height: 36px;
      .padding-l(16px);
      cursor: pointer;
    }
  }
  .local {
    &__selected {
      display: flex;
      color: #22222250;
      font-size: 12px;
      cursor: pointer;
      white-space: nowrap;
      .selected-icon {
        margin: 0 14px 0 7px;
        font-weight: 600;
      }
      .rotate-up {
        transform: rotate(180deg);
      }
      .line {
        width: 1px;
        height: 14px;
        background: #e5e5e5;
        margin-right: 10px;
      }
      &.active {
        color: #222;
      }
    }
  }}
</style>
